//import './App.css';
import Header from './components/Header'
import Index from './components/Index';
import { HashRouter, Routes, Route} from 'react-router-dom';
import Bingo from './components/Bingo';
import Comics from './components/Comics';
import Error from './components/Error';
import Gradia from './components/Gradia';
import Links from './components/Links';
import Footer from './components/Footer';

function App() {
  return (
    <HashRouter>
    <div className="App">
      <Header />
        <Routes>
          <Route path="/edsite" element={<Index />} />
          <Route path="/" element={<Index />} />
          <Route path="/bingo" element={<Bingo />} /> 
          <Route path="/comics" element={<Comics />} />
          <Route path="/dnd" element={<Gradia />}/>
          <Route path="*" element={<Error />} />
          <Route path="/links" element={<Links />} />
        </Routes>
        <Footer />
    </div>
    </HashRouter>
  );
}

export default App;
