import React, {Component} from "react";

class Comics extends Component{
    render(){
        return(
            <main>
                <h2>Comics</h2>
                <p>Some light reading.</p>
                <h3>Black Magic Blues</h3>
                <blockquote><i><p>A Final Fantasy XIV fan comic.</p>
                <p>Jangrel Plonks is a drifter in Thanalan. Bringing with him only his trusty chocobo L'Amour, and a modest amount of latent magic, he moves from town to town, rarely staying in one place for any longer than a day. When Jangrel saves the life of Edoka Modoka, talent scout for the Thaumaturge’s Guild, she immediately attempts to recruit him. But, despite her best efforts, nothing she can do or say will convince him. Will Edoka be able to get through to our pint-sized pal? Or will she keep making offers that he can – and will! – refuse?</p>
                <p>Also contains the one-shot "A Reason to Come Back" and a few bonus sketches.</p></i> 
                </blockquote>
                <iframe title="Black Magic Blues" frameborder="0" src="https://itch.io/embed/2390279?dark=true" width="552" height="167"><a href="https://blackmagicblues.itch.io/black-magic-blues">Black Magic Blues by blackmagicblues</a></iframe>
                <h3>IA IA IA</h3>
                <blockquote>
                <p>IA IA IA <i>is the story of a duck journeying to a new world. It is also the story of two rabbit knights, one very big dragon, a government cover-up, robots, Matryoshka dolls, a village of Komodo dragons, the production of leather, and a thieving magpie (no relation).</i></p>
                <p>IA IA IA <i>is a story about the end of the world.</i></p>
                <p>IA IA IA <i>can be read for free <a href="https://ia-ia-ia.world/">here.</a></i></p>

                </blockquote>
            </main>
        );
    }
}

export default Comics;