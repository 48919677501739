import React, {Component} from "react";
import { Link } from "react-router-dom";

class Header extends Component{
    render(){
        return(
            <header id="top">
            <Link to="/"><h1>edsite.black</h1></Link>
            <nav id="navigation">
                <Link to="/">HOME</Link>
                <Link to="/bingo">BINGO</Link>
                <Link to="/comics">COMICS</Link>
                <Link to="/dnd">GRADIA UPDATE</Link>
                <Link to="/links">OTHER SITES</Link>
            </nav>
            </header>
        );
    }
}

export default Header;